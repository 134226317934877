<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-btn color="success" @click="excel">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" dense fixed-header>
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.mfr="{ item }">
          {{ item.products.mfr }}
        </template>
        <template v-slot:item.partNumber="{ item }">
          {{ item.products.part_num }}
        </template>
        <template v-slot:item.partName="{ item }">
          {{ item.products.part_name }}
        </template>
        <template v-slot:item.partDesc="{ item }">
          {{ item.products.part_desc }}
        </template>
        <template v-slot:item.price="{ item }">
          {{ formatPrice(item.products.priceIdr) }}
        </template>
        <template v-slot:item.total="{ item }">
          {{ formatPrice(item.products.priceIdr * item.qty_in) }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import fileDownload from "js-file-download";

export default {
  name: "report-inventory-aging",
  data() {
    return {
      items: [],
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "RR#",
          sortable: false,
          value: "rr_number",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "RR DATE",
          sortable: false,
          value: "in_date",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Mfr",
          sortable: false,
          value: "mfr",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part#",
          sortable: false,
          value: "partNumber",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part Name",
          sortable: false,
          value: "partName",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part Description",
          sortable: false,
          value: "partDesc",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Qty",
          sortable: false,
          value: "qty_in",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Price Idr",
          sortable: false,
          value: "price",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Total Idr",
          sortable: false,
          value: "total",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Aging Days",
          sortable: false,
          value: "aging",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/inventoryAgingExcel")
        .then(response => {
          fileDownload(response.data, `inventoryAging.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/inventoryAging")
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  mounted() {
    this.search();
  },
};
</script>

<style></style>
